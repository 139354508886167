.e_profile {
    background-image: linear-gradient(180deg, #f6ecff 0%, rgba(248, 245, 252, 0) 100%);
    width: 18.875rem;
    @media (max-width: 576px) {
        width: 16.875rem;
    }

    .e_profile_bg {
        background-image: url("../../Assets/Images/Header/profile-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../Assets/Images/Header/profile-bg.png");
            background-size: cover;
            background-position: center;
            transform: scale(1.5); /* Scale the background */
            z-index: -1; /* Keep it behind the content */
        }
    }
    .e_profile_hover {
        &:hover {
            .e_arrow_right {
                transition: transform 0.3s ease;
                transform: translateX(6px);
            }
        }
    }

    .e_menu_scroll {
        overflow-y: scroll;
    }
}
