@import "../color.module.scss";

.e_update_investment {
    .e_dialog {
        max-width: 25.5rem;
        margin: auto;

        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1rem;
        border: none;
    }
}
