@import "../color.module.scss";

.e_email {
    display: flex !important;
    justify-content: center;

    .e_dialog {
        max-width: 25rem;
        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);
    }
}
