@charset "UTF-8";
/*
 *   File : global.scss
 *   Author : https://evoqins.com
 *   Description : SCSS file
 *   Integrations : NA
 *   Version : 1.0.0
*/

// @import "../Style/style.scss";
@import "../Styles/font.scss";
@import "../Styles/color.module.scss";

// -----------Body-----------------------

body {
    background-color: $white-lilac;
    overflow-x: hidden;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    display: none !important;
}

//--------------- Global ------------------
.no-decoration {
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: none;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.list-style-none {
    list-style: none;
}

// outline
.no-outline {
    outline: none;
}

.outline-deep-carmine-pink {
    outline: 1px solid $deep-carmine-pink;
}

.white-space-nowrap {
    white-space: nowrap;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

//--------------- Colors -----------------
.color-white {
    color: $white;
}

.color-black {
    color: $black;
}

.color-charleston-green {
    color: $charleston-green;
}

.color-dark-jungle-green {
    color: $dark-jungle-green;
}

.color-deep-carmine-pink {
    color: $deep-carmine-pink;
}

.color-charcoal-blue {
    color: $charcoal-blue;
}

.color-eerie-black {
    color: $eerie-black;
}

.color-blue-gray {
    color: $blue-gray;
}

.color-dark-gray {
    color: $dark-gray;
}

.color-charcoal-gray {
    color: $charcoal-gray;
}

.color-steel-blue {
    color: $steel-blue;
}

.color-rebecca-purple {
    color: $rebecca-purple;
}

.color-american-purple {
    color: $american-purple;
}

.color-onyx {
    color: $onyx;
}

.color-davy-gray {
    color: $davy-gray;
}

.color-gunmetal-gray {
    color: $gunmetal-gray;
}

.color-jet-gray {
    color: $jet-gray;
}

.color-eminence {
    color: $eminence;
}

.color-quartz {
    color: $quartz;
}

.color-dark-charcoal {
    color: $dark-charcoal;
}

.color-black-olive {
    color: $black-olive;
}

.color-regalia {
    color: $regalia;
}

.color-yale-blue {
    color: $yale-blue;
}

.color-dim-gray {
    color: $dim-grey;
}

.color-slate-gray {
    color: $slate-gray;
}

.color-ocean-blue {
    color: $ocean-blue;
}

.color-green {
    color: $green;
}
.color-crimson-red {
    color: $crimson-red;
}
.color-muted-gray {
    color: $muted-gary;
}

.color-sky-blue {
    color: $sky-blue;
}

.color-gunmetal {
    color: $gunmetal;
}

.color-outer-space {
    color: $outer-space;
}

.color-medium-gray {
    color: $medium-gray;
}

.color-granite-gray {
    color: $granite-gray;
}

.color-davys-grey {
    color: $davys-grey;
}

.color-ufo-green {
    color: $ufo-green;
}

.color-go-green {
    color: $go-green;
}

.color-chinese-black {
    color: $chinese-black;
}

.color-vivid-orange {
    color: $vivid-orange;
}

.color-graphite {
    color: $graphite;
}

.color-mikado-yellow {
    color: $mikado-yellow;
}

.color-mikado-yellow {
    color: $mikado-yellow;
}

.color-pastel-red {
    color: $pastel-red;
}

.color-vivid-gamboge {
    color: $vivid-gamboge;
}

.color-jet {
    color: $jet;
}

.color-dark-grayish-blue {
    color: $dark-grayish-blue;
}

.color-emerald-green {
    color: $emerald-green;
}

.color-golden-orange {
    color: $golden-orange;
}

.color-dark-slate {
    color: $dark-slate;
}

.color-tangerine {
    color: $bright-tangerine;
}

.color-lavender-white {
    color: $lavender-white;
}

.color-sunset-orange {
    color: $sunset-orange;
}

.color-gamboge {
    color: $gamboge;
}

.color-dim-grey {
    color: $dim-grey;
}

.color-cyan-cornflower-blue {
    color: $cyan-cornflower-blue;
}

.color-palatinate-blue {
    color: $palatinate-blue;
}

.color-ebony {
    color: $ebony;
}

.color-dim-gray {
    color: $dim-gray;
}

.color-hot-pink {
    color: $hot-pink;
}

.color-kelly-green {
    color: $kelly-green;
}

.color-metallic-yellow {
    color: $metallic-yellow;
}

.color-sunglow {
    color: $sunglow;
}

.color-dark-green {
    color: $dark-green;
}

.color-dark-golden {
    color: $dark-golden;
}

.color-bright-periwinkle {
    color: $bright-periwinkle;
}
// Font family
.e-montserrat-regular {
    font-family: "Montserrat-Regular";
}

.e-montserrat-medium {
    font-family: "Montserrat-Medium";
}

.e-montserrat-semi-bold {
    font-family: "Montserrat-SemiBold";
}

.e-montserrat-bold {
    font-family: "Montserrat-Bold";
}

// ------------Box shadow----------

.box-shadow-black {
    box-shadow:
        0px 0px 2px 0px rgba($black, 0.21),
        0px 0px 1px 0px rgba($black, 0.2);
}

.box-shadow-3b {
    box-shadow: 0px 0px 1px 0px $transparent-black;
}

.box-shadow-33 {
    box-shadow: 0px 0px 2px 0px rgba($black, 0.2);
}

.box-shadow-36 {
    box-shadow: 0px 0px 2px 0px rgba($black, 0.21);
}

.box-shadow-charleston {
    box-shadow: 0px 1px 2px 0px $transparent-dark-charcoal;
}

.box-shadow-36-charleston {
    box-shadow:
        0px 0px 2px 0px rgba($black, 0.21),
        0px 1px 2px 0px $transparent-dark-charcoal;
}

.blur-3px {
    filter: blur(3px);
}

// ----------- BG color-----------

.e-bg-rebecca-purple {
    background-color: $rebecca-purple;
}

.e-bg-lavender-blush {
    background-color: $lavender-blush;
}

.e-bg-crimpson-red {
    background: $crimpson-red;
}

.e-bg-lavender-web {
    background-color: $lavender-web;
}

.e-bg-lavender-mist {
    background-color: $lavender-mist;
}

.e-bg-bright-gray {
    background-color: $bright-gray;
}

.e-bg-magnolia {
    background-color: $magnolia;
}

.e-bg-yale-blue {
    background-color: $yale-blue;
}

.e-bg-light-periwinkle {
    background-color: $light-periwinkle;
}

.e-bg-light-blue {
    background-color: $light-blue;
}

.e-bg-light-lavender {
    background-color: $light-lavender;
}

.e-bg-black {
    background-color: $black;
}

.e-bg-lavender-frost {
    background-color: $lavender-frost;
}

.e-bg-whispering-lavender {
    background-color: $whispering-lavender;
}

.e-bg-green {
    background-color: $green;
}

.e-bg-cultured {
    background-color: $cultured;
}

.e-bg-ivory-bridge {
    background-color: $ivory-bridge;
}

.e-bg-misty-rose {
    background-color: $misty-rose;
}

.e-bg-error-red {
    background-color: $error-red;
}

.e-bg-lavender-light {
    background-color: $lavender-light;
}

.e-bg-soft-green {
    background-color: $soft-green;
}

.e-bg-white-4 {
    background-color: rgba($white, 0.4);
}

.e-bg-eminence {
    background-color: $eminence;
}

.e-bg-old-lace {
    background-color: $old-lace;
}

.e-bg-purple-white {
    background-color: $purple-white;
}

.e-bg-papaya-whip {
    background-color: $papaya-whip;
}

.e-bg-green-NCS {
    background-color: $green-NCS;
}

.e-bg-persian-blue {
    background-color: $persian-blue;
}

.e-bg-dark-tangerine {
    background-color: $dark-tangerine;
}

.e-bg-flirt {
    background-color: $flirt;
}

.e-bg-pastel-purple {
    background-color: $pastel-purple;
}

.e-bg-lavender-blue {
    background-color: $lavender-blue;
}

.e-bg-light-white {
    background-color: $light-white;
}

.e-bg-mint-cream {
    background-color: $mint-cream;
}

.e-bg-pale-lavender {
    background-color: $pale-lavender;
}

.e-bg-pale-green {
    background-color: $pale-green;
}

// ----------- border color-----------
.border-silver-sand {
    border-color: $silver-sand !important;
}

.border-light-periwinkle {
    border-color: $light-periwinkle !important;
}

.border-purple {
    border-color: $rebecca-purple !important;
}

.border-davy-gray {
    border-color: $davy-gray !important;
}

.border-athens-gray {
    border-color: $athens-gray !important;
}

.border-bright-gray {
    border-color: $bright-gray !important;
}

.border-medium-slate-blue {
    border-color: $medium-slate-blue !important;
}

.border-light-gray {
    border-color: $light-gray !important;
}

.border-white {
    border-color: $white !important;
}

.border-onyx {
    border-color: $onyx !important;
}

.border-light-grayish-blue {
    border-color: $light-grayish-blue !important;
}

.border-chinese-white {
    border-color: $chinese-white !important;
}

.border-mystic-gray {
    border-color: $mystic-gray !important;
}

.border-light-purple {
    border-color: $light-purple !important;
}

.border-platinum {
    border-color: $platinum !important;
}

.border-gainsboro {
    border-color: $gainsboro !important;
}

.border-ash-gray {
    border-color: $ash-gray !important;
}

.border-deep-carmine-pink {
    border-color: $deep-carmine-pink !important;
}

.border-light-salmon-pink {
    border-color: $light-salmon-pink !important;
}

.border-lavender-blue {
    border-color: $lavender-blue !important;
}

.border-bottom-black-0D {
    border-color: rgba($black, 0.051) !important;
}

.border-color-chinese-white {
    border-color: $chinese-white !important;
}

.border-light-orange {
    border-color: $light-orange !important;
}

.border-light-sky-blue {
    border-color: $light-sky-blue !important;
}

.border-eucalyptus {
    border-color: $eucalyptus !important;
}

.border-platinum-about {
    border-color: $platinum-about !important;
}

.border-transparent {
    border-color: transparent !important;
}

/* ================================
            truncate
=================================== */

.truncate-first-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.truncate-second-line {
    display: -webkit-box;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rotate-up {
    transform: rotate(180deg);
}

.rotate-left {
    transform: rotate(180deg);
}

.rotate-90deg {
    transform: rotate(90deg);
}

.text-transform-upper {
    text-transform: uppercase;
}

.text-transform-lower {
    text-transform: lowercase;
    &::placeholder {
        text-transform: lowercase !important;
    }
}

.user-select-none {
    user-select: none;
}

/* ================================
            Common

            // padding, border-radius, margin, gap----
// use padding-32px-all
=================================== */

// opacity

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-none {
    opacity: 1;
}

$start-value: 0;
$end-value: 500;

@for $i from $start-value through $end-value {
    $conversion-value: $i / 16;
    // border-radius
    .border-radius-#{$i}px {
        border-radius: $conversion-value * 1rem;
    }

    .border-radius-tlr-#{$i}px {
        border-top-left-radius: $conversion-value * 1rem;
        border-top-right-radius: $conversion-value * 1rem;
    }

    .border-radius-blr-#{$i}px {
        border-bottom-left-radius: $conversion-value * 1rem;
        border-bottom-right-radius: $conversion-value * 1rem;
    }

    .border-radius-trbr-#{$i}px {
        border-top-right-radius: $conversion-value * 1rem;
        border-bottom-right-radius: $conversion-value * 1rem;
    }

    .border-radius-tlbl-#{$i}px {
        border-top-left-radius: $conversion-value * 1rem;
        border-bottom-left-radius: $conversion-value * 1rem;
    }

    .border-radius-tl-#{$i}px {
        border-top-left-radius: $conversion-value * 1rem;
    }

    .border-radius-tr-#{$i}px {
        border-top-right-radius: $conversion-value * 1rem;
    }

    .border-radius-bl-#{$i}px {
        border-bottom-left-radius: $conversion-value * 1rem;
    }

    .border-radius-br-#{$i}px {
        border-bottom-right-radius: $conversion-value * 1rem;
    }

    // padding-all
    .padding-#{$i}px-all {
        padding: $conversion-value * 1rem;
    }

    // padding-top
    .padding-#{$i}px-top {
        padding-top: $conversion-value * 1rem;
    }

    // padding-bottom
    .padding-#{$i}px-bottom {
        padding-bottom: $conversion-value * 1rem;
    }

    // padding-left
    .padding-#{$i}px-left {
        padding-left: $conversion-value * 1rem;
    }

    // padding-right
    .padding-#{$i}px-right {
        padding-right: $conversion-value * 1rem;
    }

    // padding top and bottom
    .padding-#{$i}px-tb {
        padding-top: $conversion-value * 1rem;
        padding-bottom: $conversion-value * 1rem;
    }

    // padding left and right
    .padding-#{$i}px-lr {
        padding-left: $conversion-value * 1rem;
        padding-right: $conversion-value * 1rem;
    }

    // margin-all
    .margin-#{$i}px-all {
        margin: $conversion-value * 1rem;
    }

    // margin-top
    .margin-#{$i}px-top {
        margin-top: $conversion-value * 1rem;
    }

    // margin-bottom
    .margin-#{$i}px-bottom {
        margin-bottom: $conversion-value * 1rem;
    }

    // margin-left
    .margin-#{$i}px-left {
        margin-left: $conversion-value * 1rem;
    }

    // margin-right
    .margin-#{$i}px-right {
        margin-right: $conversion-value * 1rem;
    }

    // margin top and bottom
    .margin-#{$i}px-tb {
        margin-top: $conversion-value * 1rem;
        margin-bottom: $conversion-value * 1rem;
    }

    // margin left and right
    .margin-#{$i}px-lr {
        margin-left: $conversion-value * 1rem;
        margin-right: $conversion-value * 1rem;
    }

    // gap
    .gap-#{$i}px {
        gap: $conversion-value * 1rem;
    }

    .row-gap-#{$i}px {
        row-gap: $conversion-value * 1rem;
    }

    .top-#{$i}px {
        top: $conversion-value * 1rem;
    }

    .right-#{$i}px {
        right: $conversion-value * 1rem;
    }

    .left-#{$i}px {
        left: $conversion-value * 1rem;
    }

    .bottom-#{$i}px {
        bottom: $conversion-value * 1rem;
    }

    .bottom--#{$i}px {
        bottom: -$conversion-value * 1rem;
    }

    .top--#{$i}px {
        top: -$conversion-value * 1rem;
    }

    .right--#{$i}px {
        right: -$conversion-value * 1rem;
    }

    .w-#{$i}px {
        width: $conversion-value * 1rem;
    }

    .h-#{$i}px {
        height: $conversion-value * 1rem;
    }

    .min-w-#{$i}px {
        min-width: $conversion-value * 1rem;
    }

    .min-h-#{$i}px {
        min-height: $conversion-value * 1rem;
    }

    .max-h-#{$i}px {
        max-height: $conversion-value * 1rem;
    }

    .max-w-#{$i}px {
        max-width: $conversion-value * 1rem;
    }

    // font and line height
    .e-font-#{$i} {
        font-size: $conversion-value * 1rem;
    }

    .e-line-height-#{$i} {
        line-height: $conversion-value * 1rem;
    }
}

.border-point5px {
    border: 0.5px solid;
}

.margin--3px-top {
    margin-top: -3px;
}
.border-top-point5px {
    border-top: 0.5px solid;
}

.border-bottom-point5px {
    border-bottom: 0.5px solid;
}

// z index
// Define the maximum z-index value
$max-z-index: 100;

// Generate z-index classes dynamically using SCSS loop
@for $i from 1 through $max-z-index {
    .z-index-#{$i} {
        z-index: $i;
    }

    .border-#{$i}px {
        border: #{$i}px solid;
    }

    .border-bottom-#{$i}px {
        border-bottom: #{$i}px solid;
    }

    .border-top-#{$i}px {
        border-top: #{$i}px solid;
    }

    .border-tb-#{$i}px {
        border-top: #{$i}px solid;
        border-bottom: #{$i}px solid;
    }

    .border-right-#{$i}px {
        border-right: #{$i}px solid;
    }
}

.letter-spacing-point15 {
    letter-spacing: 0.15000000596046448px;
}

.letter-spacing-minus2em {
    letter-spacing: -0.02em;
}

.letter-spacing-02em {
    letter-spacing: 0.02em;
}

.letter-spacing-007em {
    letter-spacing: 0.007em;
}

.font-style-italic {
    font-style: italic;
}

.word-break {
    word-break: break-word;
}
/* ===================================
 Width -- percent
====================================== */
.w-0 {
    width: 0;
}

.w-1rem {
    width: 0.063rem !important;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-50 {
    width: 50%;
}

.w-55 {
    width: 55%;
}

.w-57 {
    width: 57%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}

.w-100 {
    width: 100%;
}

.w-32vw {
    width: 32vw;
}

.w-auto {
    width: auto;
}

.w-max-content {
    width: max-content !important;
}

.w-fit-content {
    width: fit-content;
}

/* ===================================
     Height--percent
====================================== */
.h-max-content {
    height: max-content !important;
}

.h-40 {
    height: 40% !important;
}

.h-50 {
    height: 50% !important;
}

.h-60 {
    height: 60% !important;
}

.h-70 {
    min-height: 70% !important;
}

.h-80 {
    min-height: 80% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.h-fit-content {
    height: fit-content !important;
}

.min-h-70vh {
    min-height: 70vh;
}

.max-h-100 {
    max-height: 100%;
}

.max-h-84vh {
    max-height: 84vh;
}

.min-h-100vh {
    min-height: 100vh;
}

.max-h-inherit {
    max-height: inherit !important;
}

.max-h-130px {
    max-height: 8.125rem;
}

.h-30vh {
    height: 30vh !important;
}

.h-62vh {
    height: 56.3vh !important;
}

.h-60vh {
    height: 60vh;
}

.h-70vh {
    height: 70vh;
}

.h-80vh {
    height: 80vh;
}

.h-88vh {
    height: 88vh;
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: 100vh;
}

.h-max-content {
    height: max-content;
}

/* min height */
.min-h-100vh {
    min-height: 100vh;
}

.min-h-30vh {
    min-height: 30vh !important;
}

.min-h-47vh {
    min-height: 47vh !important;
}

.min-h-50vh {
    min-height: 50vh;
}

.min-h-120px {
    min-height: 120px;
}

// cursor
.cursor-pointer {
    cursor: pointer;
}

// ellipsis
.text-ellipsis {
    text-overflow: ellipsis;
}

// top percent
.top-50-percent {
    top: 50%;
}

.top-60-percent {
    top: 60%;
}

// object-fit
.object-fit-contain {
    object-fit: contain;
}

.overflow-x-hidden {
    overflow-x: hidden;
}
