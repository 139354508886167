@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@import "./color.module.scss";

@media only screen and (max-width: 1400px) {
    .xl-border-none {
        border: none;
    }

    .e-login-wrapper {
        width: 55vw;
    }

    .e-goal-chart-height {
        height: 266px;
    }
}

@media only screen and (max-width: 1200px) {
    .e-home-page .e-words-slider {
        bottom: 0px;
    }

    .e-login-wrapper {
        width: 60vw;
    }

    .e-placards {
        height: 300px;
    }

    .gap-40px {
        gap: 16px;
    }

    .e-goal-chart-height {
        height: 238px;
    }
}

@media only screen and (max-width: 992px) {
    #root {
        overflow-x: hidden;
    }

    .top-30px {
        top: 1rem;
    }

    .e-amc-scroll {
        max-height: 9.125rem;
    }

    .e-category-scroll {
        max-height: 12.875rem;
    }

    .e-border-amc {
        border-right: 1px solid $platinum;
    }

    .e-login-wrapper {
        width: 70vw;
        padding: 2rem 2rem 2.5rem 2rem;
    }

    .padding-32px-bottom {
        padding-bottom: 1rem;
    }

    .margin-32px-bottom {
        margin-bottom: 1rem;
    }

    .padding-96px-top {
        padding-top: 2rem;
    }

    .padding-66px-top,
    .padding-52px-top {
        padding-top: 1rem;
    }

    .e-goal-chart-height {
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .e-report-popup {
        position: fixed !important;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: none;
        width: max-content;
        height: max-content;
    }
    .mySwiper {
        height: auto;
    }
    .padding-144px-bottom {
        padding-bottom: 3rem;
    }
    .e-explore-filter-modal {
        width: 65%;
    }

    .top-30px {
        top: 0.5rem;
    }

    .row-gap-24px {
        row-gap: 16px;
    }

    .e-font-24 {
        font-size: 1.25rem;
    }

    .e-font-18 {
        font-size: 1rem;
    }

    .e-amc-scroll,
    .e-category-scroll {
        max-height: 6.25rem;
    }

    .e-border-amc {
        border-bottom: 1px solid $platinum;
        border-right: none;
    }

    .e-login-wrapper {
        width: 80vw;
        padding: 1.5rem 1.5rem 2rem 1.5rem;
    }

    .padding-96px-top {
        padding-top: 1.5rem;
    }

    .padding-66px-top,
    .padding-52px-top {
        padding-top: 0.5rem;
    }

    .e-financial-image {
        height: 140px;
        object-fit: contain;
    }

    .e-slider-image {
        width: 250px;
        height: 250px;
        object-fit: contain;
    }

    .e-goal-chart-height {
        height: auto;
    }
}

@media only screen and (max-width: 576px) {
    .e-home-page .e-words-slider{
        bottom: 4px;
    }
    .swiper-pagination {
        bottom: 0px;
        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
        }
    }
    .e-faq .e-faq-accordian .e-heading::after {
        width: 16px;
        height: 16px;
        background-size: 16px;
    }
    .e-risk-view {
        height: 120px;
    }
    .e-xs-font-12 {
        font-size: 0.75rem !important;
    }
    .e-basket-name {
        font-size: 0.65rem;
    }
    .e-basket-gap {
        .min-w-8px {
            min-width: 6px;
        }
        .min-h-8px {
            min-height: 6px;
        }
        .gap-8px {
            gap: 4px;
        }
    }

    .row-gap-64px {
        row-gap: 20px;
    }
    .e-radio-gap {
        gap: 10px;
    }
    .e-slider-input {
        width: 5rem;
        padding-left: 20px;
        padding-right: 20px;
    }

    .e-slider-amount-input {
        width: 7rem;
        padding-left: 30px;
        padding-right: 20px;
    }

    .e-slider-edited-input {
        width: 5rem;
        padding-left: 30px;
        padding-right: 35px;
    }
    .e-portfolio-section {
        min-height: auto;
    }
    .e-view-all {
        font-size: 0.75rem !important;
    }
    .e-explore-filter {
        width: 14px;
    }

    .e-explore-filter-modal {
        width: 85%;
    }

    .e-tab {
        padding: 8px 0;
    }

    .xs-w-700px {
        width: 500px;
        overflow-x: scroll;
    }

    .e-profile-dropdown {
        width: 280px !important;
    }

    .xs-w-180px {
        width: 180px !important;
    }

    .xs-w-240px {
        width: 240px !important;
    }

    .xs-w-100 {
        width: 100% !important;
    }

    .gap-32px,
    .gap-40px {
        gap: 16px;
    }

    .gap-24px {
        gap: 16px;
    }

    .e-date {
        font-size: 0.625rem !important;
        line-height: 0.875rem !important;
    }

    .e-font-24,
    .e-font-32,
    .e-font-28 {
        font-size: 1.125rem;
    }

    .e-line-height-24,
    .e-line-height-28,
    .e-line-height-32 {
        line-height: 1.5rem;
    }

    .e-line-height-38,
    .e-line-height-32,
    .e-line-height-30 {
        line-height: 1.875rem;
    }

    .e-line-height-42,
    .e-line-height-40,
    .e-line-height-44 {
        line-height: 2rem;
    }

    .e-font-18,
    .e-font-20 {
        font-size: 1rem;
    }

    // .e-line-height-20 {
    //     line-height: 0.875rem;
    // }

    .e-font-16 {
        font-size: 0.875rem;
    }

    .padding-50px-tb {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .padding-38px-lr {
        padding-left: 20px;
        padding-right: 20px;
    }

    .padding-40px-lr {
        padding-left: 20px;
        padding-right: 20px;
    }

    .margin-40px-top {
        margin-top: 1.5rem;
    }

    .margin-30px-top {
        margin-top: 0.5rem;
    }

    .margin-30px-tb,
    .margin-28px-tb {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .padding-32px-top {
        padding-top: 1rem;
    }

    .padding-30px-bottom {
        padding-bottom: 1rem;
    }

    .margin-32px-bottom {
        margin-bottom: 1rem;
    }

    .margin-22px-bottom {
        margin-bottom: 0.5rem;
    }

    .margin-32px-top {
        margin-top: 0.875rem;
    }

    .top-30px {
        top: 0.125rem;
    }

    .xs-h-80px {
        height: 80px;
    }

    .xs-w-80px {
        width: 80px;
    }

    .e-login-wrapper {
        width: 90vw;
        padding: 1rem 1rem 2rem 1rem;
    }

    .padding-96px-top {
        padding-top: 1rem;
    }

    .padding-66px-top,
    .padding-52px-top {
        padding-top: 0.25rem;
    }

    .e-risk-image {
        width: 48px;
        height: 48px;
    }

    .e-explore-list-height {
        // height: 73vh;
        overflow-y: scroll;
    }

    .e-goal-chart-height {
        height: auto;
    }

    .e-pagination {
        gap: 1rem;
        font-size: 0.625rem;
        .e-transform-left-arrow,
        .next svg {
            width: 18px;
            height: 18px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .e-basket-name {
        font-size: 0.65rem !important;
    }
    .e-basket-gap {
        gap: 6px;
        .min-w-8px {
            min-width: 6px;
        }
        .min-h-8px {
            min-height: 6px;
        }
        .gap-8px {
            gap: 2px;
        }
    }
    .e-explore-filter-modal {
        width: 90%;
    }

    .w-40px {
        width: 30px;
    }

    .h-40px {
        height: 30px;
    }

    .e-fund-filter {
        padding: 6px 8px !important;
    }

    .gap-16px {
        gap: 8px;
    }

    .e-line-height-20 {
        line-height: 0.875rem;
    }

    .e-font-14 {
        font-size: 0.75rem;
    }
    .e-font-12 {
        font-size: 0.625rem;
    }

    .margin-12px-top {
        margin-top: 0.5rem;
    }

    .e-font-24,
    .e-font-32 {
        font-size: 1rem;
    }

    .e-line-height-22,
    .e-line-height-24 {
        line-height: 1.25rem;
    }

    .e-line-height-28,
    .e-line-height-32,
    .e-line-height-30 {
        line-height: 1.25rem;
    }

    .e-line-height-42 {
        line-height: 1.5rem;
    }

    .e-font-18,
    .e-font-20,
    .e-font-16 {
        font-size: 0.75rem;
    }

    .e-goal-chart-height {
        height: auto;
    }
}
