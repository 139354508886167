@import "../color.module.scss";

.e_fund_card {
    // box-shadow: 0px 4px 16px 0px $transparent-midnight-blue;
    transition: 0.3s all ease-in-out;

    &:hover {
        transition: 0.3s all ease-in-out;
        // outline: 1px solid $rebecca-purple;
        transform: scale(1.05);
        cursor: pointer;
        // box-shadow:
        //     0px 0px 2px 0px rgba($black, 0.2),
        //     0px 4px 16px 0px $transparent-midnight-blue,
        //     0px 0px 2px 0px rgba($black, 0.2);
    }
}
