@import "../color.module.scss";

.e_cancel_sip {
    .e_dialog {
        max-width: 25.5rem;

        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);

        @media (max-width: 576px) {
            .e_cancel {
                width: 80px;
                height: 80px; // Full width for small screens
            }

            h5 {
                font-size: 16px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}
