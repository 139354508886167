@import "../color.module.scss";

.e_select_career_modal {
    .e_dialog {
        max-width: 25rem;

        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;

        .e_list {
            max-height: 23.75rem;
            overflow-y: scroll;
        }

        .e_description::marker {
            font-size: 8px;
        }

        .e_flower {
            width: 28px;
            height: 28px;
        }
    }
}
