@import "../color.module.scss";

.e_input_wrapper {
    &[data-disabled="true"] {
        pointer-events: none;
        cursor: default;
    }

    .e_input,
    .e_textarea {
        border: 0.03125rem solid $silver-sand;
        /* 1px converted to rem */
        border-radius: 10px;
        padding: 0.875rem 0.75rem;
        border-radius: 0.75rem;
        outline: none;

        &::placeholder {
            font-size: 0.875rem;
            line-height: 20px !important;
            font-family: "Montserrat-Regular";
            color: $cadet-gray;
            text-transform: capitalize;
        }

        &:disabled {
            background-color: $light-lavender;
        }
    }

    .e_textarea {
        border-radius: 14x;
    }

    .e_input,
    .e_textarea {
        &:focus // &:valid
        {
            border-color: $rebecca_purple;
        }
    }

    // .e_input,
    // .e_textarea {
    //     &:not(:placeholder-shown) {
    //         border-color: $rebecca_purple !important;
    //     }
    // }

    textarea {
        resize: none;
    }
}

.e_amount_input_wrapper {
    .e_input {
        border: 0.03125rem solid $silver-sand;
        /* 1px converted to rem */
        border-radius: 10px;
        padding: 0.875rem 0.875rem 0.875rem 2.25rem;
        border-radius: 0.75rem;
        outline: none;

        &::placeholder {
            font-family: "Montserrat-Regular" !important;
            font-size: 0.857rem;
            line-height: 20px !important;
            letter-spacing: 0.009rem;
            color: $cadet-gray;
        }

        @media (max-width: 576px) {
            padding: 0.875rem 0.875rem 0.875rem 1.8rem;

            &::placeholder {
                font-size: 0.75rem;
                line-height: 18px !important;
            }
        }

        &:focus // &:valid
        {
            border-color: $rebecca_purple;
        }

        // &:not(:placeholder-shown) {
        //     border-color: $rebecca_purple !important;
        // }
        &:disabled {
            background-color: $light-lavender;
        }
    }

    .e_prefix {
        top: 50%;
        transform: translateY(-50%);
    }
}

.e_file_input {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23662D91FF' stroke-width='2' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 12px;
}
