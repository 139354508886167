/*  File : color.scss
*   Author : https://evoqins.com
*   Description : SCSS file for define and export colors
*   Integrations : NA
*   Version : 1.0.0
*/

// Color declaration
$white: #ffffff;
$black: #000000;
$red: #f6172d;
$rebecca-purple: #662d91;
$crimson-red: #d92d20;
$charleston-green: #23262f;
$white-lilac: #f4eff8;
$dark-jungle-green: #101828;
$silver-sand: #bdbdbd;
$cadet-gray: #9ea4a8;
$deep-carmine-pink: #d92d20;
$charcoal-blue: #344054;
$eerie-black: #141514;
$blue-gray: #475467;
$light-rebecca-purple: #8557a7;
$dark-gray: #444444;
$charcoal-gray: #444954;
$light-periwinkle: #eaecf0;
$steel-blue: #667085;
$lavender-blush: #efe0ff;
$light-lavender: #faf6fe;
$american-purple: #3c115a;
$lavender-web: #f0dbff;
$eminence: #65308c;
$spanish-gray: #999999;
$gunmetal: #363942;
$platinum: #e2dce7;
$davy-gray: #4f524f;
$gunmetal-gray: #45464b;
$jet-gray: #393939;
$magnolia: #f3eafd;
$medium-persian-blue: #026aa2;
$alice-blue: #f0f9ff;
$lavender-mist: #f7edff;
$quartz: #4b4a4a;
$bright-gray: #e4e7ec;
$medium-slate-blue: #8061db8c;
$dark-charcoal: #313131;
$black-olive: #3a3a3a;
$regalia: #522474;
$yale-blue: #2d4991;
$dim-grey: #4e4e4e;
$slate-gray: #666d7f;
$light-blue: #eff8ff;
$ocean-blue: #175cd3;
$green: #00a048;
$light-gray: #d4d4d4;
$onyx: #47474726;
$muted-gary: #4e534f;
$sky-blue: #6585f5;
$lavender-frost: #f0eaf4;
$athens-gray: #e5e7e9;
$light-grayish-blue: #d0d5dd;
$whispering-lavender: #faf6ff;
$chinese-white: #e5e9e6;
$mystic-gray: #eceef2;
$outer-space: #414441;
$light-purple: #eadafa;
$crimpson-red: #c91d30;
$transparent-black: #0000003b;
$transparent-navy-blue: #100b2712;
$ivory-bridge: #fff3de;
$vivid-orange: #e88e24;
$transparent-midnight-blue: #100b2714;
$transparent-dark-charcoal: #1018280d;
$medium-gray: #6d7275;
$granite-gray: #4d4c4c;
$davys-grey: #545554;
$ufo-green: #33ca5a;
$gainsboro: #e4dcdc;
$arsenic: #404348;
$teal-deer: #a8fdb0;
$waterspout: #94feeb;
$mindaro: #e0ff86;
$light-green: #85fb83;
$ghost-white: #f4eaff;
$go-green: #13b059;
$slate-blue: #7f56d9;
$dark-silver: #6d716d;
$anti-flash-white: #f0f1f2;
$chinese-black: #181614;
$cultured: #f7f7f7;
$ash-gray: #e6e6e7;
$graphite: #494848;
$mikado-yellow: #f9c608;
$mikado-yellow: #f9c608;
$misty-rose: #fee4e2;
$mikado-yellow: #f9c608;
$pastel-red: #ff625f;
$pastel-magenta: #f393be;
$sandy-brown: #f5a561;
$medium-aquamarine: #76d2a8;
$maya-blue: #6ac5ec;
$vivid-gamboge: #ff9a02;
$jet: #343434;
$light-salmon-pink: #ffa39d;
$error-red: #fff4f4;
$lavender-blue: #f4f5fe;
$lavender-light: #faf2ff;
$lavender-white: #fcfbfd;
$dark-grayish-blue: #353945;
$emerald-green: #17b55d;
$golden-orange: #e2a60c;
$dark-slate: #555955;
$soft-green: #b9d6c0;
$kelly-green: #4db24c;
$citrus-green: #bbd104;
$amber: #f8bd17;
$metallic-yellow: #ffcc17;
$bright-tangerine: #ffa619;
$cherry-red: #d44c4e;
$burgundy: #961414;
$sunset-orange: #f55454;
$light-orange: #ffe5ab;
$old-lace: #fcf3dd;
$gamboge: #df8601;
$purple-white: #f8f0ff;
$papaya-whip: #fcf2d8;
$papaya-whip: #fcf2d8;
$green-NCS: #069a6e;
$persian-blue: #2530cb;
$dark-tangerine: #f99f19;
$flirt: #9a0664;
$pastel-purple: #faf5ff;
$cyan-cornflower-blue: #1694c9;
$lavender-blue: #ebf7ff;
$palatinate-blue: #3538cd;
$light-sky-blue: #bae6fd;
$sunglow: #fec42f;
$light-white: #fdfbff;
$dark-charcoal-shade: #464746;
$mint-cream: #d6f5e2;
$eucalyptus: #48bf89;
$ebony: #090b0b;
$pale-lavender: #fbf6ff;
$dim-gray: #6b6a6a;
$platinum-about: #e7e7e7;
$hot-pink: #ff64c0;
$pale-green: #ecfff2;
$dark-green: #059c38;
$dark-golden: #c75802;
$bright-periwinkle: #767dff;

:export {
    white: $white;
    black: $black;
    rebecca_purple: $rebecca-purple;
    charleston_green: $charleston-green;
    dark_jungle_green: dark_jungle_green;
    dark_jungle_green: $dark-jungle-green;
    magnolia: $magnolia;
    medium_persian_blue: $medium-persian-blue;
    alice_blue: $alice-blue;
    athens_gray: $athens-gray;
    eerie_black: $eerie-black;
    onyx: $onyx;
    bright_gray: $bright-gray;
    light_purple: $light-purple;
    silver_sand: $silver-sand;
    medium_gray: $medium-gray;
    light_lavender: $light-lavender;
    platinum: $platinum;
    light_lavender: $light-lavender;
    gainsboro: $gainsboro;
    slate_blue: $slate-blue;
    dark_silver: $dark-silver;
    anti_flash_white: $anti-flash-white;
    chinese_white: $chinese-white;
    deep_carmine_pink: $deep-carmine-pink;
    davy_gray: $davy-gray;
    pastel_magenta: $pastel-magenta;
    sandy_brown: $sandy-brown;
    medium_aquamarine: $medium-aquamarine;
    maya_blue: $maya-blue;
    steel_blue: $steel-blue;
    kelly_green: $kelly-green;
    citrus_green: $citrus-green;
    amber: $amber;
    bright_tangerine: $bright-tangerine;
    cherry_red: $cherry-red;
    burgundy: $burgundy;
    vivid_orange: $vivid-orange;
    sunglow: $sunglow;
    sky_blue: $sky-blue;
    hot_pink: $hot-pink;
}
