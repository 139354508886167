@import "../color.module.scss";

.e_risk_score {
    .e_dialog {
        max-width: 35rem;
        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        height: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);
    }
}
