@import "../color.module.scss";

.e_switch {
    .e_dialog {
        max-width: 30.5rem;

        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1rem;
        border: none;
        .e_height {
            max-height: 27rem;
            overflow-y: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                display: block !important;
                width: 0.5rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2.25rem;
                background-color: $platinum !important;
            }
        }
        .e_fund {
            max-height: 20.5rem;
            overflow-y: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                display: block !important;
                width: 0.5rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2.25rem;
                background-color: $platinum !important;
            }
        }
        .e_unit {
            padding: 0.875rem !important;
        }
    }
}
