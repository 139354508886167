@import "../color.module.scss";

.e_manage_bank {
    .e_dialog {
        max-width: 25.5rem;

        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_fields {
        // max-height: 40vh;
        // overflow-y: scroll;
        padding-right: 24px;
        &::-webkit-scrollbar {
            display: block !important;
            width: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2.25rem;
            background-color: $platinum;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);
    }
}
