@import "../color.module.scss";

.e_active_btn:hover {
    // opacity: 0.8;
}

// inactive btn style
.e_inactive_btn {
    pointer-events: none;
    opacity: 0.8;
}

.e_height {
    height: 50px;
    @media only screen and (max-width: 576px) {
        height: 46px;
    }
}
