@import "../color.module.scss";

.e_dob {
    .e_dialog {
        max-width: 25rem;
        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);
    }

    .e_fields {
        height: 240px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 4px;
        &::-webkit-scrollbar {
            display: block !important;
            width: 8px !important;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2.25rem;
            background-color: $platinum;
        }
    }
}
