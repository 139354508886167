@import "../color.module.scss";

.e_fund_redeem {
    .e_dialog {
        max-width: 30rem;
        margin: auto;

        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1rem;
        border: none;

        .e_input {
            &:focus {
                border-color: $rebecca-purple !important;
            }

            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                font-family: "Montserrat-Regular";
                color: $cadet-gray;
            }
        }
    }
}
