@import "../color.module.scss";

.e_confirm_modal {
    // display: flex !important;
    // justify-content: center;
    margin: 0 auto;

    .e_dialog {
        max-width: 24rem;
        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);

        .e_list {
            max-height: 23.75rem;
            overflow-y: scroll;
        }

        .e_description::marker {
            font-size: 8px;
        }

        .e_info {
            background-color: $ivory-bridge;
            color: $vivid-orange;
        }
    }
}
