@import "./color.module.scss";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.e-login-wrapper {
    box-shadow:
        0px 0px 2px 0px rgba($black, 0.21),
        0px 0px 1px 0px rgba($black, 0.2);
    background-color: $white;
    padding: 3rem 3.875rem 2.625rem 3.875rem;
    width: 55vw;
    background-image: url("../Assets/Images/Global/bg-image.webp");
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.e-auth-slider .slick-dots {
    bottom: 1.25rem !important;
}

.e-auth-slider .slick-dots li,
.e-auth-slider .slick-dots li button {
    width: 0.375rem !important;
    height: 0.375rem !important;
}

.e-auth-slider .slick-dots li.slick-active,
.e-auth-slider .slick-dots li.slick-active button {
    width: 24px !important;
    height: 0.375rem !important;
}

.e-auth-slider .slick-dots li button::before {
    opacity: 0.5 !important;
    color: $light-rebecca-purple !important;
    width: 0.375rem !important;
    height: 0.375rem !important;
}

.e-auth-slider .slick-dots li.slick-active button:before {
    opacity: 10 !important;
    color: transparent !important;
    background-color: $rebecca-purple !important;
    height: 0.375rem !important;
    width: 24px !important;
    top: 0.375rem;
    border-radius: 100px !important;
}

.e-login-seperator {
    height: 100%;
}

.offcanvas-backdrop {
    width: 100%;
}

.e-login-seperator::after {
    content: "";
    position: absolute;
    height: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    right: 20%;
    background-image: url("../Assets/Images/Global/line.svg");
    background-repeat: no-repeat;

    // border-image-source: linear-gradient(90deg, #fffefe 0%, #eaeaea 45.5%, #fffefe 100%);
    // border-image-slice: 1;
    // border-image-width: 1px;
}

.e-notification-wrapper:hover {
    background-color: $white-lilac;
}

.e-seperator {
    height: 100%;
}

.e-seperator::after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    right: -5%;
    background-image: url("../Assets/Images/Global/line.svg");
    background-repeat: no-repeat;

    // border-image-source: linear-gradient(90deg, #fffefe 0%, #eaeaea 45.5%, #fffefe 100%);
    // border-image-slice: 1;
    // border-image-width: 1px;
}

.e-link:hover {
    text-decoration: underline;
    color: $rebecca-purple;
}

.e-logo {
    cursor: pointer;
    transition: 0.5s ease-in-out;
    &:hover {
        transition: all 0.5s ease-in-out;
        transform: scale(1.09);
    }
}

.e-login-logo {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
        transition: all 0.3s ease-in-out;
        transform: scale(1.05);
    }
}

.e-social-icon {
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    &:hover {
        transform: scale(1.1);
        transition: 0.3s all ease-in-out;
    }
}

// home page
.e-home-page {
    background: linear-gradient(180deg, $rebecca-purple 0%, $eminence 100%);
    border-bottom-left-radius: 52px;

    .e-home-banner-slider {
        bottom: 60px;

        li {
            margin: 0;

            button {
                &::before {
                    color: $spanish-gray;
                    opacity: 0.5 !important;
                    font-size: 0.5rem;
                }
            }
        }

        .slick-active {
            button {
                &::before {
                    color: $eminence !important;
                    opacity: 1 !important;
                    font-size: 0.625rem;
                }
            }
        }
    }

    .e-words-slider {
        @extend .e-home-banner-slider;
        bottom: 24px;
    }

    .e-bg-gradient {
        background: $white;
        background-image: linear-gradient(180deg, $ghost-white 0%, rgba(238, 223, 253, 0) 111.85%);
        border-left: 1px solid $platinum;
        border-bottom-left-radius: 40px;
    }

    .e-color-gradient {
        background-image: conic-gradient(
            from 196.38deg at 55.42% 50%,
            $teal-deer 0deg,
            $waterspout 90deg,
            $mindaro 203.4deg,
            $light-green 360deg
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
    }

    .e-play-button {
        left: -1rem;
    }
}

.Toastify__toast-body {
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    color: $eerie-black;
    padding: 0.375rem 0.75rem 0.375rem 0.375rem !important;
}

.Toastify__toast--success .Toastify__toast-icon svg {
    fill: $rebecca-purple;
    /* Change the fill color to purple or your desired color */
}

.Toastify__progress-bar--success {
    background: $rebecca-purple !important;
}

.slider-track-0 {
    background-color: $rebecca-purple;
}

.e-goal-bg {
    background: url("../Assets/Images/Goal/goal-bg.png");
    background-size: cover;
    width: 100%;
    box-shadow:
        0px 3px 1rem 0px $transparent-navy-blue,
        0px 0px 2px 0px rgba($black, 0.2);
}

.e-goal-summary {
    background: url("../Assets/Images/Goal/goal-summary.png");
    background-size: cover;
    width: 100%;
    height: auto;
    box-shadow:
        0px 3px 1rem 0px $transparent-navy-blue,
        0px 0px 2px 0px rgba($black, 0.2);
}

.outline-none:focus {
    outline: none;
}

.sip-date-picker {
    box-shadow: 5px 3px 0.625rem 0px rgba($black, 0.1);
}

// profile header modal
.e-profile-hover {
    &:hover {
        .e-arrow-right {
            transition: transform 0.3s ease;
            transform: translateX(0.625rem);
        }
    }
}

// calculator page
.e-calculator {
    box-shadow:
        0px 0px 2px 0px rgba($black, 0.2),
        0px 0px 1px 0px rgba($black, 0.21);
    // box-shadow: 0px 0px 1.68px 0px #00000036, 0px 0px 1.68px 0px #00000036;
}

.e-chart-active-color {
    &::before {
        position: absolute;
        content: "";
        width: 0.625rem;
        height: 0.625rem;
        background-color: $rebecca-purple;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 100%;
    }
}

.e-chart-inactive-color {
    &::before {
        position: absolute;
        content: "";
        width: 0.625rem;
        height: 0.625rem;
        background-color: $hot-pink;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 100%;
    }
}

.e-chart-data {
    bottom: 25%;
}

.e-sip-date:hover {
    background-color: $magnolia;
    border-radius: 100px;
}

.e-customize-btn {
    border-radius: 150px;
    background-color: $white;
    border: 1px solid transparent;
    box-shadow: 0px 0px 3px 0px $transparent-black;
    color: $rebecca-purple;
}

.e-customize-btn:hover {
    border: 1px solid $rebecca-purple;
    background-color: $light-lavender;
    cursor: pointer;
    color: $rebecca-purple;
}

.e-banner {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    border-radius: 24px;
}

.e-banner .e-banner-img {
    transition: all 0.3s ease-in-out;
    transition-duration: 2s;
}

.e-error-svg svg path {
    stroke: $deep-carmine-pink;
    stroke-width: 104;
}
.e-banner:hover .e-banner-img {
    transition: 0.3s all ease-in-out;
    transform: scale(1.1);
    -o-transform: scale(1.1);
    border-radius: 24px;
    transition-duration: 2s;
    cursor: pointer;
}
.e-arrow-right {
    transition: 0.3s all ease-in-out;
}

.e-customize-btn:hover .e-arrow-right {
    transform: translateX(2px);
    transition: 0.3s all ease-in-out;
}

.e-add-btn {
    z-index: 4;
    border-radius: 20px;
    background-color: $white;
    border: 1px solid $light-gray;
    box-shadow: 0px 0px 1px 0px rgba($black, 0.21);
}

.e-add-btn:hover {
    border: 1px solid $rebecca-purple;
    cursor: pointer;
    background-color: $lavender-white;
}

.e-date {
    font-size: 0.7rem !important;
    padding: 0.5rem !important;
}

.e-arc div:first-child {
    width: inherit !important;
    height: inherit !important;
}

// .e-border:not(:last-child) .e-progress-image::before {
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 1px;
//     left: 1.25rem;
//     z-index: 1;
//     background: $soft-green;
// }

// .e-vertical-border:not(:last-child) .e-progress-image::before {
//     content: "";
//     position: absolute;
//     width: 1px;
//     height: 24vh;
//     top: 18px;
//     left: 0.625rem;
//     right: 0;
//     z-index: 1;
//     background: $soft-green;
// }

// date picker
.rdp-day_selected {
    background: $rebecca-purple !important;
}

.rdp-day_selected:hover {
    background: $rebecca-purple !important;
    color: $white !important;
}

.rdp-months {
    display: block !important;
}

.rdp-day_range_middle {
    background-color: $lavender-frost !important;
    color: $dark-charcoal !important;
}

.rdp-table {
    max-width: 100% !important;
}

.rdp-month {
    background-color: $white !important;
    border-radius: 1rem !important;
    padding: 0.625rem !important;
    box-shadow: 0px -1px 1rem 0px rgba($black, 0.1019607843);
}

.rdp-month .rdp-dropdown_month,
.rdp-month .rdp-dropdown_year {
    padding: 8px 0 !important;
    font-size: 0.75rem !important;
}

.rdp-dropdown {
    padding-left: 4px !important;
}

.rdp {
    position: relative;
    left: -1rem;
    width: 100%;
    margin-right: 0px;
    font-family: "Montserrat-Regular" !important;
}

.rdp-day:hover,
.rdp-nav button:hover {
    background-color: $spanish-gray;
}

// .e-app-zoom-level-9 {
//     zoom: 0.9 !important;
// }

// epxlore page
.e-search-fund {
    box-shadow:
        0px 0px 2px 0px #00000036,
        0px 0px 1px 0px #00000033,
        0px 0px 1px 0px #00000033,
        0px 0px 1px 0px #00000033;
}

// .e-resource-slider {
//     .slick-slide {
//         height: 100%;
//     }

//     .slick-track {
//         display: flex !important;
//         height: auto;
//     }

//     .slick-list {
//         display: flex !important;
//         height: 100%;
//     }

//     .slick-slide div {
//         height: 100%;
//     }
// }

.e-search-icon {
    top: 0;
    bottom: 0;
    left: 0.75rem;
    margin: auto;
}

.e-filter {
    box-shadow:
        0px 0px 2px 0px #00000036,
        0px 0px 1px 0px #00000033;
}

.e-amc-scroll,
.e-category-scroll {
    max-height: 15.125rem;
    overflow-y: scroll;
    margin-right: -10px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: block !important;
        width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2.25rem;
        background-color: $platinum !important;
    }
}

.show-scroll-y {
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        display: block !important;
        width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2.25rem;
        background-color: $platinum !important;
    }
}

.e-table-scroll div {
    &::-webkit-scrollbar {
        display: block !important;
        height: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background-color: $platinum !important;
    }
}

// dot in explore and fund detail page
.e-type {
    &::after {
        position: absolute;
        content: "";
        width: 0.375rem;
        height: 0.375rem;
        background-color: $dark-charcoal-shade;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -0.688rem;
        margin: auto;
        border-radius: 100%;
    }
}

.e-explore-filter-left-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
    /* Adjust the duration and timing function as needed */
}

.e-explore-filter {
    transition: transform 0.3s ease-in-out;
    /* Ensure the initial state also has the transition */
}

// explore pagination
.e-transform-left-arrow {
    transform: rotate(180deg);
}

.e-pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    color: $arsenic;
    text-align: center;
    font-family: "Montserrat-Medium";
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
}

.e-pagination-active > a {
    padding: 0.625rem 1.125rem;
    background-color: $light-lavender;
    border-radius: 0.5rem;
    font-family: "Montserrat-SemiBold";
    font-weight: 600;
    color: $rebecca-purple !important;
}

// fund detail page
.e-fund-detail-chart {
    box-shadow:
        0px 0px 2px 0px #00000036,
        0px 0px 1px 0px #00000033;
}

.e-ranking-category {
    box-shadow: 0px 0px 2px 0px #00000036;
}

.line-hover {
    border: 1px dashed;
}

.e-kyc-input {
    padding: 0.875rem 1rem !important;
}

.e-fund-order {
    background-image: url("../Assets/Images/Portfolio/switch_fund_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

// risk assessment
.e-risk-assessment-card {
    box-shadow:
        0px 4px 16px 0px #100b2714,
        0px 0px 2px 0px #00000033;
}

// financial freedom
.e-strength-shadow {
    box-shadow:
        0px 0px 2px 0px #00000036,
        0px 0px 1px 0px #00000033;
}

.e-max-potential {
    background: radial-gradient(50% 50% at 50.13% 50%, #faf8fc 0%, #f8f8ff 100%),
        radial-gradient(50% 50% at 50.13% 50%, #faf8fc 0%, #f8f8ff 100%),
        radial-gradient(50% 50% at 50.13% 50%, #faf8fc 0%, #f8f8ff 100%), linear-gradient(0deg, #f8f8ff, #f8f8ff);
    box-shadow:
        0px 0px 1px 0px #6585f540,
        0px 0px 1px 0px #6585f540;
}

.e-portfolio {
    background: radial-gradient(50% 50% at 50.13% 50%, #faf8fc 0%, #f5f2f9 100%),
        radial-gradient(50% 50% at 50.13% 50%, #faf8fc 0%, #f5f2f9 100%),
        radial-gradient(50% 50% at 50.13% 50%, #faf8fc 0%, #f5f2f9 100%);
    box-shadow:
        0px 0px 2px 0px #662d9133,
        0px 0px 1px 0px #662d9133;
}

.e-sip {
    box-shadow:
        0px 1.17px 2.33px 0px #1018280d,
        1.17px 0px 2.33px 0px #1018280d;
}

// rc-slider risk page step 2
.e-break-point-slider .rc-slider-rail {
    background-color: $light-periwinkle;
    height: 8px;
}

.e-break-point-slider .rc-slider-handle {
    border-color: $rebecca-purple;
    background-color: $white;
    height: 24px;
    width: 24px;
    top: -25%;
    margin-top: 0;
    opacity: 1;
}

.e-break-point-slider .rc-slider-handle:active,
.e-break-point-slider .rc-slider-handle:hover,
.e-break-point-slider .rc-slider-handle:focus {
    border-color: $rebecca-purple;
    background-color: $white;
    box-shadow: none;
}

.e-break-point-slider .rc-slider-dot {
    height: 12px;
    width: 12px;
    top: -50%;
}

.e-break-point-slider .rc-slider-dot-active {
    border-color: $rebecca-purple;
    height: 12px;
    width: 12px;
    top: -50%;
}

.e-break-point-slider .rc-slider-track {
    background-color: $rebecca-purple;
    height: 8px;
}

.e-explore-filter-modal {
    width: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.no-scroll {
    overflow: hidden;
}

// profile
.e-profile-border {
    box-shadow:
        0px 0px 2px 0px #00000036,
        0px 0px 1px 0px #00000033;
}

// support page
.e-support-table > div:first-child {
    border-radius: 0;
}

// faq page
.e-faq {
    .e-accordian-item {
        &:has(.show),
        &:has(.collapsing) {
            .e-heading {
                color: $eerie-black;
            }
        }
    }

    .e-faq-accordian {
        .e-heading {
            box-shadow: none;
            background-color: transparent;

            &::after {
                background-image: url("../Assets/Images/FAQ/accordion-down-arrow.png");
                background-size: 24px;
                width: 24px;
                // display: none;
            }
        }
    }
}

// Resource
.e-content p,
.e-content p span {
    font-family: "Montserrat-Regular" !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: $jet !important;
}

.e-content h1,
.e-content h2,
.e-content h3,
.e-content h4,
.e-content h5,
.e-content h6,
.e-content h1 span,
.e-content h2 span,
.e-content h3 span,
.e-content h4 span,
.e-content h5 span,
.e-content h6 span {
    font-family: "Montserrat-Medium" !important;
}

.e-content li,
.e-content li span {
    font-family: "Montserrat-Regular" !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $jet !important;
}

.e-content p strong,
.e-content p span strong {
    font-family: "Montserrat-Bold" !important;
}

.slick-slider {
    z-index: 1;
}

// nudge shadow
.e-nudge-first-shadow {
    width: 96%;
    height: 100%;
    bottom: -16px;
    left: 50%;
    opacity: 1;
    transform: translateX(-50%);
}

.e-nudge-second-shadow {
    @extend .e-nudge-first-shadow;
    width: 88%;
    bottom: -30px;
}

.e-financial-image {
    width: 100%;
    height: 100%;
}

// placard
.e-placards {
    > div:first-child {
        padding: 0 !important;
        overflow: unset !important;
        /* Assuming you want to remove padding */
    }

    li {
        padding: 0 !important;
        border-radius: 32px;
        height: 232px;

        section {
            height: 100%;
        }
    }
}

.first-shadow {
    background: url("../Assets/Images/Home/first-shadow.png");
    border-radius: 42px;
    padding-bottom: 10px;
    position: absolute;
    z-index: 2;
    left: 10px;
    right: 10px;
    margin: auto;
}

.swiper-pagination-bullet-active {
    background: $rebecca-purple;
}

.e-order-filter {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: block !important;
        width: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2.25rem;
        background-color: $platinum;
    }

    .e-date-popup {
        position: fixed !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

#chartjs-tooltip {
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 3px;
    padding: 10px;
    position: absolute;
    pointer-events: none;
    transform: translateX(-50%);
    /* Center the tooltip */
}

#chartjs-tooltip table {
    margin: 0;
    padding: 0;
    border-collapse: collapse;
}

#chartjs-tooltip table td {
    padding: 0;
}

.e-goal-chart-height {
    height: 286px;
}

.e-goal-chart-height-loader {
    height: 390px;
}
