@import "../color.module.scss";

.e_input_wrapper {
    &[data-disabled="true"] {
        pointer-events: none;
        cursor: default;
    }

    .e_phone_input {
        border: 0.03125rem solid $silver-sand;
        /* 1px converted to rem */
        border-radius: 0.75rem;
        padding: 0.75rem 3.125rem 0.75rem 3.375rem;

        &::placeholder {
            font-size: 0.875rem;
            line-height: 1.25rem !important;
            font-family: "Montserrat-Regular";
            letter-spacing: 0.15px;
            color: $cadet-gray;
        }
    }

    .e_phone_input {
        &:focus // &:valid
        {
            outline: none;
            border-color: $rebecca-purple !important;
        }
    }

    .e_phone_number {
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        left: 1rem;
    }

    .e_postLabel {
        top: 50%;
        right: 0;
        transform: translate(-20%, -50%);
    }
}

.e_error_input {
    .e_input,
    .e_textarea,
    .e_phone_input {
        border: 0.063rem solid $deep-carmine-pink !important;
        /* 1px converted to rem */
    }
}

.e_password_icon {
    right: 1rem;
    position: absolute;
    top: 50%;
}
