@import "../color.module.scss";

.e_fund_modal {
    .e_dialog {
        max-width: 32em;
        max-height: 400px;

        @media (max-width: 576px) {
            max-width: 90%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;

        .e_list {
            max-height: 23.75rem;
            overflow-y: scroll;
        }

        .e_description::marker {
            font-size: 8px;
        }

        .e_info {
            background-color: $ivory-bridge;
            color: $vivid-orange;
        }

        .e_fund_list {
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: block !important;
                width: 0.5rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2.25rem;
                background-color: $platinum !important;
            }
        }
    }
}

.e_confirm_modal {
    // display: flex !important;
    // justify-content: center;
    margin: 0 auto;

    .e_dialog {
        max-width: 25rem;
        @media (max-width: 576px) {
            max-width: 85%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);

        .e_list {
            max-height: 23.75rem;
            overflow-y: scroll;
        }

        .e_description::marker {
            font-size: 8px;
        }

        .e_info {
            background-color: $ivory-bridge;
            color: $vivid-orange;
        }
    }
}

.e_esign_modal {
    .e_dialog {
        max-width: 35rem;

        @media (max-width: 576px) {
            max-width: 85%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        height: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);
    }
}

.e_preview_modal {
    .e_dialog {
        max-width: 35rem;

        @media (max-width: 576px) {
            max-width: 85%; // Full width for small screens
            margin: auto;
        }
    }

    .e_content {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        border: none;
        border: 1px solid $athens-gray;
        box-shadow: 5px 3px 10px 0px rgba($black, 0.1);
    }
}
