@import "../color.module.scss";

.e_card {
    box-shadow: 0px 0px 2px 0px rgba($black, 0.2);

    &:hover {
        outline: 1px solid $rebecca-purple;
    }

    .e_date {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: $rebecca-purple;
            top: 0;
            bottom: 0;
            right: -4px;
            margin: auto;
            border-radius: 100%;
        }
    }
}