@import "../color.module.scss";

.e_goal_card {
    box-shadow:
        0px 4px 16px 0px $transparent-midnight-blue,
        0px 0px 2px 0px rgba($black, 0.2);
    transition: 0.3s all ease-in-out;
}

.e_goal_card:hover {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    box-shadow: (0 0.25rem 0.625rem rgba(0, 0, 0, 0.15));
    transform: scale(1) translate3d(0, -3%, 0);
}
