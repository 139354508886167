@import "../color.module.scss";

.e_sip_modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .e_dialog {
        max-width: 22.5rem;

        @media (max-width: 576px) {
            max-width: 20rem; // Full width for small screens
        }
    }

    .e_content {
        border: none;
    }
}
