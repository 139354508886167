@import "../color.module.scss";

.e_pin {
    width: 15.875rem;
    // margin: 0 auto;

    input {
        height: 3rem;
        border: none;
        outline: none;
        opacity: 0;
    }

    label {
        top: 1.75rem;

        div {
            width: 3rem;
            height: 3rem;
            box-shadow: 0px 1px 2px 0px $transparent-dark-charcoal;
            position: relative;
        }
        div.e_focused {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 50%;
                left: 0;
                right: 0;
                margin: auto;
                bottom: 10px;
                height: 1px;
                background-color: $eerie-black;
            }
        }
    }
}
